var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { getSingleIntQueryValue } from "../../utils/request";
import PaginationLinks from "../ui/PaginationLinks.vue";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead/dist/VueBootstrapTypeahead.common.js";
import AsyncStatus from "../../utils/async";
import debounce from "lodash/debounce";
var pageSize = 100;
var studentFragment = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment Students_student on Student {\n    id\n    fullName\n    emailAddress\n    purchasesForViewerAgentCount\n    commissionForViewerAgent\n    hasCompletedSignup\n    createdAt\n  }\n"], ["\n  fragment Students_student on Student {\n    id\n    fullName\n    emailAddress\n    purchasesForViewerAgentCount\n    commissionForViewerAgent\n    hasCompletedSignup\n    createdAt\n  }\n"])));
var debouncedPerformSearch = debounce(function performSearch(query) {
    var _this = this;
    this.searchStatus.run(this.$apollo
        .query({
        query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          query StudentsTypeaheadQuery($query: String!) {\n            viewerAgent {\n              id\n              students(name: $query, pageIndex: 0, pageSize: 10) {\n                id\n                ...Students_student\n              }\n            }\n          }\n          ", "\n        "], ["\n          query StudentsTypeaheadQuery($query: String!) {\n            viewerAgent {\n              id\n              students(name: $query, pageIndex: 0, pageSize: 10) {\n                id\n                ...Students_student\n              }\n            }\n          }\n          ", "\n        "])), studentFragment),
        variables: { query: query },
    })
        .then(function (_a) {
        var students = _a.data.viewerAgent.students;
        _this.nameMatches = students;
    }));
}, 400);
export default Vue.extend({
    components: {
        PaginationLinks: PaginationLinks,
        VueBootstrapTypeahead: VueBootstrapTypeahead,
    },
    data: function () {
        return {
            // For typing of apollo
            viewerAgent: undefined,
            searchQuery: "",
            nameMatches: [],
            selectedMatchStudent: undefined,
            searchStatus: new AsyncStatus(),
            pageSize: pageSize,
        };
    },
    apollo: {
        viewerAgent: {
            fetchPolicy: "cache-and-network",
            query: gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        query StudentsQuery($pageIndex: Int!, $pageSize: Int!, $name: String) {\n          viewerAgent {\n            id\n            studentsCount\n            students(pageIndex: $pageIndex, pageSize: $pageSize, name: $name) {\n              id\n              ...Students_student\n            }\n          }\n        }\n        ", "\n      "], ["\n        query StudentsQuery($pageIndex: Int!, $pageSize: Int!, $name: String) {\n          viewerAgent {\n            id\n            studentsCount\n            students(pageIndex: $pageIndex, pageSize: $pageSize, name: $name) {\n              id\n              ...Students_student\n            }\n          }\n        }\n        ", "\n      "])), studentFragment),
            variables: function () {
                return {
                    pageIndex: this.pageIndex,
                    pageSize: pageSize,
                };
            },
        },
    },
    computed: {
        pageIndex: function () {
            return getSingleIntQueryValue(this.$route, "page", 1) - 1;
        },
        showStudents: function () {
            if (this.selectedMatchStudent) {
                return [this.selectedMatchStudent];
            }
            if (!this.viewerAgent) {
                return [];
            }
            return this.viewerAgent.students;
        },
    },
    methods: {
        onSelectSearchStudent: function (student) {
            this.selectedMatchStudent = student;
        },
        onInputChange: function (text) {
            if (this.selectedMatchStudent) {
                this.selectedMatchStudent = undefined;
            }
            if (text.length === 0) {
                this.nameMatches = [];
            }
            else {
                this.performSearch(text);
            }
        },
        performSearch: function (query) {
            debouncedPerformSearch.call(this, query);
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Students").toString(),
        };
    },
});
var templateObject_1, templateObject_2, templateObject_3;
